import React, { useEffect, useState } from 'react'
import { Register } from '@components'
import withData from '@hoc/withData'
// eslint-disable-next-line no-unused-vars
import redirect from '@utils/redirect'
import { signInWithUser } from '../../../services/UserActions'

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const onSignIn = () => {
    signInWithUser({ username:email, password })
  }
  return (
    <Register
      header='Giriş Yap'
      onSignUp={onSignIn}
      email={email}
      password={password}
      setEmail={setEmail}
      setPassword={setPassword}
    />
  )
}

export default withData(SignIn)
